import { Notification, NotificationTranslation } from '@/context/Notification';
import { CloseIcon } from '@/theme/Icons';
import { HStack, Button, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { getFallbackLanguage } from '@/helpers/lang';

type CustomNotificationProps = {
  notification: Notification;
  onClick?: () => void;
};

const findForLanguage = (
  translations: NotificationTranslation[],
  lang: string
) => {
  return translations.find((translation) => translation.locale === lang);
};

const CustomNotification = ({
  notification,
  onClick
}: CustomNotificationProps) => {
  const { lang } = useTranslation();
  const fallbackLang = getFallbackLanguage(lang);
  const content = findForLanguage(
    notification.translations,
    fallbackLang
  )?.content;
  const cta = findForLanguage(notification.translations, fallbackLang)?.cta;
  const redirectUrl = findForLanguage(
    notification.translations,
    fallbackLang
  )?.redirectUrl;

  if (!content) {
    return null;
  }

  return (
    <HStack
      p="1rem"
      w="100%"
      maxWidth="100%"
      justifyContent="center"
      margin="0"
      alignItems="center"
      textAlign="center"
      position="relative"
      fontSize="0.9rem"
      borderBottom="1px solid #808080"
      background="#575757"
    >
      <Text>
        <span data-testid="custom-notification-content">{content}</span>{' '}
        {redirectUrl && cta && (
          <Link href={redirectUrl as string} passHref>
            <Button
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              variant="link"
              cursor="pointer"
            >
              {cta}
            </Button>
          </Link>
        )}
      </Text>
      <CloseIcon
        position="absolute"
        right={['10px', '10px', '25px']}
        onClick={onClick}
      />
    </HStack>
  );
};

export default CustomNotification;
